<template>
    <div class="select-element" :class="{'has-error': error.length, multiple, searchable}">
        <span v-if="showLabel" class="select-element__label">
            <span v-if="required" class="required">*</span> {{ placeholder }}
        </span>
        <v-select
            ref="select"
            :label="labelKey"
            :placeholder="showLabel ? '' : placeholder"
            class="form-control"
            :multiple="multiple"
            :value="valueAfterLoad"
            :reduce="option => option.id"
            :options="options"
            :searchable="searchable"
            :clearable="clearable"
            :disabled="disabled"
            @close="close"
            :name="name"
            :class="{'form-control--lightblue': lightblue, 'disabled' : disabled, 'error' : errorsList.length, multiple: multiple}"
            @input="e => this.inputEvent = e"
            @search="(search, loading) => {
            if (!searching) {
                searching = true
                fetchOptions(search).then(() => searching = false)
            }

        }"
        >
            <template #open-indicator="{ attributes }">
                <span v-if="!inputEvent" v-bind="attributes"><img src="@/assets/img/sort.svg" alt="svg"></span>
                <div v-else></div>
            </template>

            <span slot="no-options">{{$t('Sorry, nothing found')}}</span>
        </v-select>

        <input type="hidden" :name="name" v-model="valueAfterLoad">

        <span v-if="errorsList.length" class="select-element__error">{{ Array.isArray(errorsList) ? errorsList[0] : errorsList }}</span>
    </div>
</template>

<script>
export default {
    name: "SelectComponents",
    props: {
        required: {
            type: Boolean,
            required: false
        },
        labelKey: {
          default: 'label'
        },
        value: {
            required: true
        },
        name: {
            type: String,
            required: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        autoOpen: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: true
        },
        routeValue: {
            required: false
        },
        options: {
            required: true,
            type: Array
        },
        lightblue: {
            type: Boolean,
            default: false
        },
        showLabel: {
            type: Boolean,
            default: false
        },
        placeholder: {
            required: false,
            type: String
        },
        clearable: {
            required: false,
            type: Boolean,
            default: false
        },
        error: {
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            searching: false,
            inputEvent: null,
            errorsList: '',
        }
    },
    mounted() {
        if (this.value) {
            this.$el.querySelector('.vs__clear').classList.add('d-none')
        }

        if (!this.autoOpen) {
            return;
        }

        this.$refs.select.open = false;
    },
    watch: {
        inputEvent() {
            this.errorsList = ''
            this.$emit('input', this.inputEvent)
        },
        value() {
            this.$el.querySelector('.vs__clear').classList.remove('d-none')
        },
        error(){
            this.errorsList = this.error
        }
    },
    computed: {
        isLoaded() {
            return this.options && this.options.length >= 1
        },
        valueAfterLoad() {
            if (!this.isLoaded) {
                return;
            }

            if (this.routeValue) {
                if (this.options.find(x => x.isActive)) {
                    return this.$route.query[this.routeValue]
                }
                return +this.$route.query[this.routeValue]
            }

            if (Array.isArray(this.value)) {
                return this.value.map(el => +el);
            }

            if (this.options.find(x => +x.id === +this.value)) {
                return +this.value;
            }
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        fetchOptions(v) {
            return new Promise((resolve, reject) => {
                this.$emit('search', v)
                resolve(v);
            })
        }
    },
}
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";

.v-select.disabled {
    background-color: #f8f8f8 !important;
}

.vs__dropdown-toggle {
    border: none;
}

.vs__actions {
    padding: 2px;
    position: relative;
    top: 2px;

    .vs__open-indicator {
        display: flex;
    }
}

.vs__clear {
    fill: transparent;
    background: url('~@/assets/img/close.svg');
    background-position: 75% center;
    background-repeat: no-repeat;
    width: 20px;
    margin-right: 0;
}

.vs--disabled .vs__search, .vs--disabled .vs__dropdown-toggle {
    background-color: transparent !important;
}

.vs__dropdown-option.vs__dropdown-option--highlight {
    background: $color_blue;
}

.vs__dropdown-option {
    white-space: inherit;
}

.vs__selected {
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    overflow: hidden;
    //top: 2px;
}

#vs1__listbox {
    width: 99%;
    border: none;
}

.vs__selected-options {
    flex-wrap: nowrap;
    overflow: hidden;
}

.vs--unsearchable .vs__search {
    padding: 0;
}

.vs__open-indicator {
    transition: none;
}

</style>

<style lang="scss" scoped>
.required {
    color: #ff0032;
}

.form-control {
    border: solid 1px #EEF3FD;
    border-radius: 2px;
    color: #141213;
    padding: 5.5px 7px;
    background-color: white;
    font-size: 15px;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(237, 243, 255, 0.4);
    &:focus{
        border-color: red;
    }

    &--lightblue {
        background-color: rgb(237 243 255 / 40%);
    }
}

.select-element {
    position: relative;

    &__label {
        margin-bottom: 8px;
        color: $color_gray;
        display: block
    }

    &__error {
        //font-size: 13px;
        //color: $color_red;
        //position: absolute;
        //right: 0;
        //bottom: -18px;
        font-size: 13px;
        color: #FF496D;
        position: absolute;
        right: 0;
        bottom: auto;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.btn-clear-search {
    background-color: white;
    border: none;
    padding: 0;
    position: absolute;
    right: -21px;
    top: 50%;
    transform: translate(0, -50%);
}
</style>
