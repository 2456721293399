var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-element",class:{'has-error': _vm.error.length, multiple: _vm.multiple, searchable: _vm.searchable}},[(_vm.showLabel)?_c('span',{staticClass:"select-element__label"},[(_vm.required)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),_c('v-select',{ref:"select",staticClass:"form-control",class:{'form-control--lightblue': _vm.lightblue, 'disabled' : _vm.disabled, 'error' : _vm.errorsList.length, multiple: _vm.multiple},attrs:{"label":_vm.labelKey,"placeholder":_vm.showLabel ? '' : _vm.placeholder,"multiple":_vm.multiple,"value":_vm.valueAfterLoad,"reduce":function (option) { return option.id; },"options":_vm.options,"searchable":_vm.searchable,"clearable":_vm.clearable,"disabled":_vm.disabled,"name":_vm.name},on:{"close":_vm.close,"input":function (e) { return this$1.inputEvent = e; },"search":function (search, loading) {
        if (!_vm.searching) {
            _vm.searching = true
            _vm.fetchOptions(search).then(function () { return _vm.searching = false; })
        }

    }},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
    var attributes = ref.attributes;
return [(!_vm.inputEvent)?_c('span',_vm._b({},'span',attributes,false),[_c('img',{attrs:{"src":require("@/assets/img/sort.svg"),"alt":"svg"}})]):_c('div')]}}])},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t('Sorry, nothing found')))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueAfterLoad),expression:"valueAfterLoad"}],attrs:{"type":"hidden","name":_vm.name},domProps:{"value":(_vm.valueAfterLoad)},on:{"input":function($event){if($event.target.composing){ return; }_vm.valueAfterLoad=$event.target.value}}}),(_vm.errorsList.length)?_c('span',{staticClass:"select-element__error"},[_vm._v(_vm._s(Array.isArray(_vm.errorsList) ? _vm.errorsList[0] : _vm.errorsList))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }